import React, { useRef, useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import _ from 'lodash';
import { useColorPanel } from '../../providers/colorPanel/colorPanel';
import { pathLogSVG } from '../../constants';
import {
  displayTooltip,
  modeBarButtons,
} from '../utils';

const TotalFluxChart = function TotalFluxChart({
  acronym, studyId, modelId, graphName, groups = [], chartExportAudit, ...props
}) {
  const logBtn = {
    name: 'Log',
    title: 'Toggle Linear and Logarithmic Scale',
    icon: {
      width: 39,
      path: pathLogSVG,
      ascent: 24,
      descent: 0,
      transform: 'matrix(1 0 0 1 0 2)',
    },
    click: () => setLogView((logView) => !logView),
    toggle: true,
  }
  const { handleClickOpen, chartMenu, ColorButton } = useColorPanel();
  const [logView, setLogView] = useState(false);
  const plotRef = useRef(null);
  const plotlyRef = useRef(null);
  const modeBar = modeBarButtons({
    ColorButton, chartMenu, handleClickOpen, studyId, modelId, graphName, acronym, data: _.isEmpty(props.data) ? [] : props.data,
  });

  let modeBarButton = [logBtn];
  modeBarButton = [...modeBarButton, ...modeBar];
  const config = {
    responsive: true,
    displaylogo: false,
    modeBarButtons: [modeBarButton],
  };
  const [layout, setLayout] = useState({
    margin: props.margin,
    hovermode: 'closest',
    showlegend: true,
    hoverlabel: {
      bgcolor: 'white',
      font_size: 8,
    },
    xaxis: {
      title: props.xaxisTitle,
      linecolor: 'rgba(0, 0, 0, 0.25)',
      linewidth: 1,
      mirror: true,
      opacity: 0.5,
    },
    yaxis: {
      title: props.yaxisTitle,
      tickfont: { size: 10 },
      type: 'linear',
      exponentformat: 'B', // it's default value
    },
    title: {
      text: props.title,
      x: 0.05,
      y: 1.5,
    },
  });

  useEffect(() => {
    if (props.xaxisRange && props.xaxisRange.length > 0) {
      layout.xaxis.range = [props.xaxisRange[0], props.xaxisRange[1]];
      setLayout(layout);
    }
    if (props.yaxisRange && props.yaxisRange.length > 0) {
      layout.yaxis.range = [
        logView ? Math.log10(props.yaxisRange[0]) : props.yaxisRange[0],
        logView ? Math.log10(props.yaxisRange[1]) : props.yaxisRange[1],
      ]
      setLayout(layout);
    }
  }, [props.xaxisRange, props.yaxisRange, layout, props, logView]);

  useEffect(() => {
    const newLayout = {
      ...layout,
      yaxis: {
        ...layout.yaxis,
        type: logView ? 'log' : 'linear',
        exponentformat: logView ? 'power' : 'B',
      },
    }
    if (props.yaxisRange && props.yaxisRange.length > 0) {
      layout.yaxis.range = [
        logView ? Math.log10(props.yaxisRange[0]) : props.yaxisRange[0],
        logView ? Math.log10(props.yaxisRange[1]) : props.yaxisRange[1],
      ]
    }
    setLayout(newLayout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logView]);

  useEffect(() => {
    if (plotlyRef.current === null) {
      const plotDownloadButton = document.querySelectorAll('[data-title="Download plot as a png"]')
      if (plotDownloadButton.length) {
        const downloadButton = plotDownloadButton[0]
        plotlyRef.current = downloadButton
        plotlyRef.current.addEventListener('mouseup', chartExportAudit)
      }
    }
  });

  console.log(`Data for ${props.title} total flux graph`, { layout, data: props.data })

  return (
    <Plot
      ref={plotRef}
      style={{ width: '100%', height: '100%' }}
      data={props.data}
      layout={layout}
      config={config}
      onAfterExport={chartExportAudit}
      useResizeHandler
      onAfterPlot={displayTooltip(groups, plotRef.current?.el)}
      onInitialized={displayTooltip(groups)}
    />
  );
}

export default TotalFluxChart;
