import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import {
  formatStudyDay,
  formatStudyDayWithDate,
  getDataForBarGraph,
  modeBarButtons,
} from '../utils';
import { BAR_LAYOUT } from '../../constants';
import { useColorPanel } from '../../providers/colorPanel/colorPanel';

const TVCChartSessionSummary = ({
  sessionDay,
  chartExportAudit,
  tumorVolumeChangeGraphdata,
  sessionDaysTumorVolumeChange,
  studyId,
  modelId,
  isReferenceLineCheckboxChecked,
  referenceLinePercentage,
  isMaximumCutoffCheckboxChecked,
  maximumCutoffValue,
  setMaximumCutoffValue,
  setMaximumCutoffValueToPass,
}) => {
  const acronym = 'TVC';
  const {
    handleClickOpen,
    chartMenu,
    ColorButton,
    getColors,
  } = useColorPanel();
  const groupsData = [];
  tumorVolumeChangeGraphdata.forEach((item) => {
    if (item.group !== '') {
      groupsData.push({
        name: item.group,
      });
    }
  });
  const modeBar = modeBarButtons({
    ColorButton,
    chartMenu,
    handleClickOpen,
    studyId,
    modelId,
    graphName: `${acronym}-summary`,
    acronym,
    data: groupsData,
  });
  const config = {
    responsive: true,
    displaylogo: false,
    modeBarButtons: [modeBar],
  };
  const plotRef = useRef(null);
  const plotlyRef = useRef(null);
  const [data, setData] = useState([
    {
      type: 'bar',
      x: [],
      y: [],
      width: [],
      marker: {
        color: [],
      },
    },
  ]);
  const [layout, setLayout] = useState({
    ...BAR_LAYOUT,
  });

  useEffect(() => {
    if (sessionDaysTumorVolumeChange.length && tumorVolumeChangeGraphdata.length) {
      const sessionDayRepeat = {};
      const sessions = (sessionDaysTumorVolumeChange || []).map((sessionDay) => {
        if (!sessionDayRepeat[sessionDay.day]) {
          sessionDayRepeat[sessionDay.day] = [];
        }
        sessionDayRepeat[sessionDay.day] = [...sessionDayRepeat[sessionDay.day], sessionDay];
        const val = formatStudyDayWithDate(sessionDay.day, sessionDay.date, sessionDay.time);
        return {
          key: formatStudyDay(sessionDay.day, sessionDay.time),
          value: val,
          day: sessionDay.day,
        };
      });
      const session = sessions.find((session) => session.value === sessionDay) || {};
      if (sessions.length) {
        const newData = getDataForBarGraph(
          tumorVolumeChangeGraphdata,
          session.key,
          getColors,
          isMaximumCutoffCheckboxChecked,
          maximumCutoffValue,
        );
        setData(newData);
        if (maximumCutoffValue === '' || maximumCutoffValue === undefined) {
          setMaximumCutoffValue(100);
          setMaximumCutoffValueToPass(100);
        }

        // Add reference line to the layout if referenceLinePercentage is provided
        if (referenceLinePercentage && isReferenceLineCheckboxChecked) {
          const referenceLineY = newData[0].y.map(() => referenceLinePercentage);
          setLayout({
            ...BAR_LAYOUT,
            title: `Waterfall (Tumor Volume Change, Day ${session.day} - Session ${
              sessionDayRepeat[session.day]?.length
            })`,
            shapes: [
              {
                type: 'line',
                x0: 0,
                x1: 1,
                y0: referenceLineY[0],
                y1: referenceLineY[referenceLineY.length - 1],
                xref: 'paper',
                yref: 'y',
                line: {
                  color: 'black',
                  width: 2,
                  dash: 'dash', // Set the line style (e.g., 'solid', 'dash', 'dot', etc.)
                },
              },
            ],
          });
        } else {
          // No reference line if referenceLinePercentage is not provided
          setLayout({
            ...BAR_LAYOUT,
            title: `Waterfall (Tumor Volume Change, Day ${session.day} - Session ${
              sessionDayRepeat[session.day]?.length
            })`,
            shapes: [],
          });
        }
      }
    }
  }, [
    sessionDay,
    tumorVolumeChangeGraphdata,
    sessionDaysTumorVolumeChange,
    setLayout,
    setData,
    getColors,
    isReferenceLineCheckboxChecked,
    referenceLinePercentage,
    isMaximumCutoffCheckboxChecked,
    maximumCutoffValue,
    setMaximumCutoffValue,
    setMaximumCutoffValueToPass,
  ]);

  useEffect(() => {
    if (plotlyRef.current === null) {
      const plotDownloadButton = document.querySelectorAll('[data-title="Download plot as a png"]');
      if (plotDownloadButton.length) {
        const downloadButton = plotDownloadButton[0];
        plotlyRef.current = downloadButton;
        plotlyRef.current.addEventListener('mouseup', chartExportAudit);
      }
    }
  });

  console.log('Data for session summary graph', { layout, data })

  if (layout.title) {
    return (
      <Plot
        ref={plotRef}
        style={{ width: '100%', height: '100%' }}
        data={data}
        layout={layout}
        config={config}
        onAfterExport={chartExportAudit}
        useResizeHandler
      />
    );
  }
  return <div>Loading...</div>;
};

TVCChartSessionSummary.propTypes = {
  sessionDay: PropTypes.shape({
    day: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired,
  }).isRequired,
  chartExportAudit: PropTypes.func.isRequired,
  tumorVolumeChangeGraphdata: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string.isRequired,
    }),
  ).isRequired,
  sessionDaysTumorVolumeChange: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      time: PropTypes.string.isRequired,
    }),
  ).isRequired,
  studyId: PropTypes.string.isRequired,
  modelId: PropTypes.string.isRequired,
  isReferenceLineCheckboxChecked: PropTypes.bool.isRequired,
  referenceLinePercentage: PropTypes.number,
  isMaximumCutoffCheckboxChecked: PropTypes.bool.isRequired,
  maximumCutoffValue: PropTypes.number,
};

TVCChartSessionSummary.defaultProps = {
  referenceLinePercentage: null,
  maximumCutoffValue: null,
};

export default TVCChartSessionSummary;
